import React from 'react'

const InsightReports = () => {
  return (
    <div>
      <div className='container'>
    <h3>Insight Reports</h3>
      </div>
    </div>
  )
}

export default InsightReports
